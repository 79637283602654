<template>
	<q-card style="width: 700px; max-width: 80vw">
		<q-card-section>
			<div class="text-h6">Manage visibility</div>
		</q-card-section>
		<q-card-section class="q-py-none">
			<q-item tag="label">
				<q-item-section>
					<q-item-label> Show fields </q-item-label>
				</q-item-section>
				<q-item-section avatar class="flex items-center">
					<q-checkbox v-model="show_fields"/>
				</q-item-section>
			</q-item>
			<q-item tag="label">
				<q-item-section>
					<q-item-label> Show conditions </q-item-label>
				</q-item-section>
				<q-item-section avatar class="flex items-center">
					<q-checkbox v-model="show_conditions" />
				</q-item-section>
			</q-item>
			<q-item tag="label">
				<q-item-section>
					<q-item-label> Show content </q-item-label>
				</q-item-section>
				<q-item-section avatar class="flex items-center">
					<q-checkbox v-model="show_content"/>
				</q-item-section>
			</q-item>
		</q-card-section>
		<q-card-actions class="justify-end">
			<q-btn flat label="Cancel" color="primary" @click="$emit('close')"/>
			<q-btn flat label="Apply" color="primary" @click="applySettingsChanges"/>
		</q-card-actions>
	</q-card>
</template>

<script>
export default {
	name: "SettingsDialog",
	data (){
		return{
			show_fields: true,
			show_conditions: true,
			show_content: true,
		}
	},
	props:{
		item_id: Number,
	},
	methods:{
		applySettingsChanges(){
			this.$store.dispatch("toggleFieldsVisibility", {fg_id: this.item_id, visible: this.show_fields})
			this.$store.dispatch("toggleConditionsVisibility", {fg_id: this.item_id, visible: this.show_conditions})
			this.$store.dispatch("toggleContentVisibility", {fg_id: this.item_id, visible: this.show_content})
			this.$emit('close')

		}
	},
	created() {
		let fg = this.$store.getters.field_groups[this.item_id]
		this.show_fields = fg.show_fields
		this.show_conditions = fg.show_conditions
		this.show_content = fg.show_content

	}
}
</script>