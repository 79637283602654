<template>
	<div v-if="form">
		<component
			:is='getTag()'
			class="q-pa-xs"
			v-if="item_in_view"
			id='sticky-sidebar'
			:class="$q.screen.gt.xs ? 'sticky-sidebar' : 'sticky-footer' "
		>
			<div class="sidebar-items">
				<q-btn
					v-if="this.field_groups_list.length && !form.is_inherited"
					flat
					round
					color="dark"
					icon="o_settings"
					@click.prevent="show_settings_dialog = true"
					class="cursor-pointer"
				>
					<q-tooltip anchor="top middle" :delay="500">
						Field group settings
					</q-tooltip>
				</q-btn>
				<q-btn
					v-if='$q.screen.gt.xs && this.field_groups_list.length && !form.is_inherited'
					flat
					round
					icon="o_save"
					color="dark"
					@click="saveFields"
					class="cursor-pointer"
				>
					<q-tooltip anchor="top middle" :delay="500">
						Save field group
					</q-tooltip>
				</q-btn>
				<q-btn
					v-if="$q.screen.lt.sm && !form.is_inherited"
					flat
					round
					icon="o_save"
					color="dark"
					@click="saveAllFieldGroups()"
					class="cursor-pointer"
				>
					<q-tooltip anchor="top middle" :delay="500">
						Save all changes 
					</q-tooltip>
				</q-btn>
				<q-btn
					v-if="this.field_groups_list.length && !form.is_inherited "
					flat
					round
					icon="o_delete"
					color="dark"
					class="cursor-pointer"
					@click="show_delete_dialog=true"
				>
					<q-tooltip anchor="top middle" :delay="500">
						Delete field group
					</q-tooltip>
				</q-btn>
				<q-btn
					v-if="!form.is_inherited "
					flat
					round
					icon="o_splitscreen"
					color="dark"
					class="cursor-pointer"
					@click="add_field_group = true"
				>
					<q-tooltip anchor="top middle" :delay="500">
						Add field group
					</q-tooltip>
				</q-btn>
				<q-btn
					flat
					round
					icon="arrow_back"
					color="dark"
					class="cursor-pointer"
					:to="{ name: 'Incident Forms'}"
				>
					<q-tooltip anchor="top middle" :delay="500">
						Back
					</q-tooltip>
				</q-btn>
			</div>
		</component>
		<div :class="$q.screen.gt.xs ? 'q-pa-xl' : 'q-pa-sm'" id="wrapper">
			<div @click.stop class="full-width">
				<div class="row justify-between">
					<h1 class="text-h5 q-pb-md q-my-md text-left fields-tab-header full-width">
						{{ form_name }}
						<q-icon name="edit" />
					</h1>
				</div>
				<q-popup-edit v-model="form_name" auto-save @hide="updateNameBeforeSave(form_name)">
					<template>
						<q-input
							v-model="form_name"
							dense
							autofocus
							:readonly="form.is_inherited"
						/>
					</template>
				</q-popup-edit>
			</div>
			<div :class="{'q-pr-xl' : $q.screen.gt.xs}" v-if="form" :key="form.id">
				<draggable
					v-model="field_groups_list"
					v-bind="dragOptions"
					handle=".handle"
					@start="drag = true"
					@end="drag = false"
					@sort="sort"
				>
					<div v-for="field_group in field_groups_list" :key="field_group.id">
						<FieldGroup
							:fg_id="field_group.id"
							:fg_order="field_group.order"
							:form_id="form.id"
							:fg_is_open="field_group.is_open"
							:force_save="force_save"
							:save_fields="save_fields"
							:is_inherited="form.is_inherited"
							:selected_field_group="item_in_view"
							@field-group-selected="fieldGroupSelected"
							@selected-field-group-deleted="changeSelectedFieldGroup"
							@update-form="updateForm"
						/>
					</div>
				</draggable>
			</div>
		</div>
		<q-dialog v-model="show_settings_dialog">
			<SettingsDialog
				:item_id="item_in_view.id"
				@close="show_settings_dialog = false"
			/>
		</q-dialog>
		<q-dialog v-model="add_field_group">
			<FieldGroupAdd
				v-if="!form.is_inherited"
				@newFieldGroupCreated="createFieldGroup"
			/>
		</q-dialog>
		<q-dialog v-model="show_delete_dialog">
			<FieldGroupDelete
				v-if="!form.is_inherited"
				:field_group_name="item_in_view.title"
				@delete-group="removeFieldGroup(item_in_view)"
				@close="show_delete_dialog = false"
			/>
		</q-dialog>
		<div class="field-fab-wrapper q-mt-md" v-if="$q.screen.gt.xs && !form.is_inherited">
			<q-btn
				size="md"
				padding="md lg"
				color="primary"
				icon="save"
				label="Save all changes"
				rounded
				@click="saveAllFieldGroups()"
			/>
		</div>
	</div>
</template>

<script>
import FormsAPI from "@/services/api/IncidentForm.js";
import FieldGroup from '@/components/incidents/forms/FieldGroup.vue';
import SettingsDialog from '@/components/incidents/forms/SettingsDialog.vue';
import FieldGroupAdd from '@/components/incidents/forms/FieldGroupAdd.vue';
import FieldGroupDelete from '@/components/incidents/forms/FieldGroupDelete.vue';
import draggable from 'vuedraggable';

import FieldGroupsAPI from '@/services/api/IncidentFieldGroup.js';
import lexorder from "@/services/lexorder.js";

import { mapActions, mapGetters } from "vuex";
import FieldsAPI from '@/services/api/IncidentFields.js';
import { required } from 'vuelidate/lib/validators';
import { uniqueIn } from "@/services/validators.js";
import FormAPI from '@/services/api/IncidentForm.js';
export default {
	name: "IncidentEditForm",
	components: {
		FieldGroup,
		FieldGroupAdd,
		draggable,
		SettingsDialog,
		FieldGroupDelete,
	},
	data (){
		return{
			drag: false,
			retrieve_form: {},
			show_delete_dialog: false,
			form: null,
			new_field_group: {
				display_conditions: [],
				content: "",
				field_mappings: [],
				form: null
			},
			force_save: false,
			form_name: '',
			new_form_name: '',
			error_message: "",
			show_field_settings: false,
			is_saved: false,
			edit_incident_name: false,
			delete_incident: false,
			edited_incident_name: '',
			has_edit_name_error: false,
			edit_name_error_message: '',
			item_in_view: {},
			show_settings_dialog: false,
			add_field_group: false,
			save_fields: false,
			show_add_dialog: false,
		}
	},
	computed: {
		...mapGetters(['current_CG', 'logged_user', 'field_groups']),
		field_groups_list: {
			get: function() {
				return Object.values(this.$store.getters.field_groups).sort(function(fg1, fg2) {
					if(fg1.order < fg2.order) { return -1; }
					if(fg1.order > fg2.order) { return 1; }
					return 0;
				});
			},
			set: function(new_val) {
				this.field_groups_list.splice(0, this.field_groups_list.length)
				for (let fg of new_val) {
					this.field_groups_list.push(fg);
				}
			}
		},
		show_all_fields: {
			get: function() {
				let list_show_fields = Object.values(this.$store.getters.field_groups).map(function (field_group) {
					return field_group.show_fields
				});
				return list_show_fields.some(x => !!x)
			},
			set: function(new_val) {
				this.$store.dispatch("toggleAllFieldsVisibility", new_val)
			}
		},
		show_all_conditions: {
			get: function() {
				let list_show_conditions = Object.values(this.$store.getters.field_groups).map(function (field_group) {
					return field_group.show_conditions
				});
				return list_show_conditions.some(x => !!x)
			},
			set: function(new_val) {
				this.$store.dispatch("toggleAllConditionsVisibility", new_val)
			}
		},
		show_all_content: {
			get: function() {
				let list_show_content = Object.values(this.$store.getters.field_groups).map(function (field_group) {
					return field_group.show_content
				});
				return list_show_content.some(x => !!x)
			},
			set: function(new_val) {
				this.$store.dispatch("toggleAllContentVisibility", new_val)
			}
		},
		dragOptions() {
			return {
				animation: 200,
				group: "description",
				disabled: false,
				ghostClass: "ghost",
				scrollSensitivity: 200,
				forceFallback: true,
			};
		},
		show_fields: {
			get: function() {
				if (this.item_in_view.id){
					return this.$store.getters.field_groups[this.item_in_view.id].show_fields;
				}else{
					return true
				}
			},
			set: function(new_val) {
				this.$store.dispatch("toggleFieldsVisibility", {fg_id: this.item_in_view.id, visible: new_val})
			}
		},
		show_conditions: {
			get: function() {
				if (this.item_in_view.id){
					return this.$store.getters.field_groups[this.item_in_view.id].show_conditions;
				}else{
					return true
				}
			},
			set: function(new_val) {
				this.$store.dispatch("toggleConditionsVisibility", {fg_id: this.item_in_view.id, visible: new_val})
			}
		},
		show_content: {
			get: function() {
				if (this.item_in_view.id){
					return this.$store.getters.field_groups[this.item_in_view.id].show_content;
				}else{
					return true
				}
			},
			set: function(new_val) {
				this.$store.dispatch("toggleContentVisibility", {fg_id: this.item_in_view.id, visible: new_val})
			}
		},
	},
	validations: {
		form_name: {
			required,
			unique: uniqueIn('forms', 'form'),
		}
	},
	methods: {
		...mapActions(['addFieldGroup', 'retrieveFieldGroups', 'resetFieldGroups', 'getAllFields', 'deleteFieldGroup']),
		deleteForm() {
			this.$emit('deleteForm', this.form.id);
			this.delete_incident = false
		},
		closePopup() {
			this.show_delete_dialog = false;
		},
		async createFieldGroup(name, field_id){
			this.new_field_group.title = name
			this.new_field_group.field_mappings.pop();
			this.new_field_group.field_mappings.push({field: field_id, is_required: false})
			let order = lexorder.generate(this.field_groups_list);
			this.$set(this.new_field_group, 'order', order);
			let response = await this.addFieldGroup(this.new_field_group);
			if (response.error){
				this.notify(response.error_message)
				return
			}
			this.add_field_group = false
			this.notify('Field group successfully created.')
			this.item_in_view = this.$store.getters.field_groups[response.data.id];
			let field_group_id = this.item_in_view.id
			setTimeout(function() {
				if (window.innerWidth > 599){
					let element = document.getElementById('field_group_' + field_group_id)
					let sidebar = document.getElementById('sticky-sidebar')
					element.scrollIntoView({
						behavior: 'auto',
						block: 'start',
						inline: 'start'
					});
					sidebar.style.top = `${150 + element.getBoundingClientRect().top}px`;
				}else{
					window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
				}
			}, 100);
		},
		async saveAllFieldGroups() {
			if (!this.form.is_inherited) {
				if (this.new_form_name){
					if (this.new_form_name.length > 99){
						this.notify('Please ensure that the form title has no more than 100 characters.')
						return
					}
					let response = await FormAPI.updateForm(this.form.id, this.new_form_name);
					if (response.error){
						this.notify(response.error_message)
						return
					}
					let form_response = await FormsAPI.retrieveForm(this.form.id)
					this.form = form_response.data
					this.form_name = this.form.name
				}
				this.force_save = !this.force_save;
				this.notify('Form changes updated successfully.')
			}
		},
		saveFields(){
			this.save_fields = !this.save_fields
		},
		showDeleteDialog() {
			if (!this.form.is_inherited) {
				this.show_delete_dialog = !this.show_delete_dialog
			}
		},
		async sort(event) {
			let curr = this.field_groups_list[event.newIndex];
			curr.order = lexorder.update(event.newIndex, this.field_groups_list);
			FieldGroupsAPI.patchFieldGroup(curr.id, {order: curr.order})
		},
		openFormNameEdit() {
			this.$refs.form_name.$el.click()
		},
		async removeFieldGroup(field_group){
			this.$q.loading.show({
				delay: 400,
			})
			let conditions_to_be_deleted = []
			field_group.field_mappings.forEach(async mapping => {
				conditions_to_be_deleted.push({fg_id: field_group.id, field_id: mapping.field.id})
			})
			
			this.show_delete_dialog = false
			let response = await this.deleteFieldGroup(field_group.id);
			if (response.error){
				this.$q.loading.hide()
				this.notify(response.error_message)
				return
			}
			this.$q.loading.hide()
			this.notify('Field group successfully deleted.')
			this.$root.$emit("deleteConditionInOtherFG", conditions_to_be_deleted)
			if (this.field_groups_list.length){
				this.item_in_view = this.field_groups_list[0]
				window.scrollTo({ top: 0, behavior: 'smooth' });
			}else{
				this.add_field_group = true
			}
		},
		async populateFieldDropDown(){
			let all_available_fields = await FieldsAPI.getFields();
			this.unused_fields.splice(0, this.unused_fields.length)
			for (let available_field of all_available_fields) {
				let found_same = false
				for (let field of this.item_in_view.field_mappings) {
					if (available_field.id === field.field.id) {
						found_same = true
						break
					}
				}

				if (!found_same) {
					this.unused_fields.push(available_field)
				}
			}
		},
		fieldGroupSelected(field_group_id){
			let id = field_group_id.split('_').pop()
			for (let item of this.field_groups_list){
				if (id == item.id){
					this.item_in_view = item
					if (window.innerWidth > 599){
						let element = document.getElementById(field_group_id)
						let sidebar = document.getElementById('sticky-sidebar')
						element.scrollIntoView({
							behavior: 'auto',
							block: 'start',
							inline: 'start'
						});
						sidebar.style.top = `${150 + element.getBoundingClientRect().top}px`;
					}
					break
				}
			}
		},
		changeSelectedFieldGroup(deleted_field_group_id){
			let group_selected = false
			if (this.field_groups_list.length){
				for (let field_group of this.field_groups_list){
					if (field_group.id != deleted_field_group_id){
						group_selected = true
						this.fieldGroupSelected(`field_group_${field_group.id}`)
						break
					}
				}
			}
			if (!group_selected){ this.item_in_view = {} }
		},
		getTag(){
			if (window.innerWidth > 599){
				return 'q-card'
			}else{
				return 'q-footer'
			}
		},
		applySettingsChanges(){
			this.show_settings_dialog = false
		},
		updateNameBeforeSave(new_name){
			this.new_form_name = new_name
		},
		async updateForm(){
			let form_id = parseInt(this.$route.params.id)
			let response = await FormsAPI.retrieveForm(form_id)
			this.form = response.data
			this.form.is_inherited = this.current_CG.customer_group.parent && this.form.customer_group !== this.logged_user.customer_group
			await this.retrieveFieldGroups(this.form.id)
			this.new_field_group.form = form_id
			this.form_name = this.form.name
			this.getAllFields();
		},
		notify(message){
			this.$q.notify({
				timeout: 6700,
				message: message,
				actions: [
					{
						label: 'Dismiss',
						color: 'primary',
					},
				],
			})
		},
	},
	async created() {
		this.$q.loading.show({
			delay: 400,
		})
		await this.updateForm()
		if (this.field_groups_list.length){
			this.item_in_view = this.field_groups_list[0]
		}else{
			this.add_field_group = true
		}
		this.$q.loading.hide()
	},
}
</script>
<style lang="scss" scoped>
.sticky-sidebar{
	display: inline-block;
	margin-bottom: 0;
	transform-origin: 0% 0%; 
	position: sticky;
	top: 145px;
	right: 30px;
    z-index: 5;
	float: right;
}
.sticky-footer{
	background: #fff;
    border-radius: 0.375rem;
    margin-left: auto;
    margin-right: auto;
}
.sidebar-items{
	display: flex;
	flex-direction: column;
	@media(max-width: 599px){
		flex-direction: row;
		justify-content: space-evenly;
	}
}
.field-fab-wrapper {
	position: sticky;
	bottom: 20px;
	z-index: 5;
	width: fit-content;
	margin: 0 auto;
}
</style>