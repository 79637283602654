<template>
	<q-card style="width: 700px; max-width: 80vw">
		<q-card-section class="text-left">
			<div>Are you sure you want to delete field group <strong>{{ field_group_name }}</strong>?</div>
		</q-card-section>
		<q-card-actions align='right'>
			<q-btn flat label="Cancel" color="primary" @click="$emit('close')" />
			<q-btn flat label="Delete" color="primary" @click="$emit('delete-group')"/>
		</q-card-actions>
	</q-card>
</template>

<script>
export default {
	name: "FieldGroupAdd",
	props: {
		field_group_name: String
	}
}
</script>
