import { render, staticRenderFns } from "./EditForm.vue?vue&type=template&id=10bb805e&scoped=true&"
import script from "./EditForm.vue?vue&type=script&lang=js&"
export * from "./EditForm.vue?vue&type=script&lang=js&"
import style0 from "./EditForm.vue?vue&type=style&index=0&id=10bb805e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10bb805e",
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPopupEdit from 'quasar/src/components/popup-edit/QPopupEdit.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QFooter from 'quasar/src/components/footer/QFooter.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QTooltip,QIcon,QPopupEdit,QInput,QDialog,QCard,QFooter});
